import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { motion, AnimatePresence } from 'framer-motion'

import "@fontsource/inter/600.css"
import "@fontsource/inter/400.css"

const Background = styled.section`
position: static;
z-index: -1;
img {
  width: 100vw;
  height: 100vh;
}
`
const Main = styled.main`
font-family: "Inter";
position: fixed;
top: 7.5vh;
width: 70vw;
margin: 0 15vw;
h1 {
  font-weight: 600;
  letter-spacing: .33px;
  font-size: 1.2rem;
  padding-bottom: 10px;
  line-height: 1.33em;
}
h3 {
  font-weight: 400;
  font-size: .8125rem;
  letter-spacing: .33px;
  line-height: 1.66em;
}
a {
  color: rgba(0,0,0,.66);
  text-decoration: none;
  border-bottom: solid 1px rgba(0,0,0,0.2);
  padding-bottom: 2px;
  transition: all 0.3s ease;
  &:hover {
    color: #000;
  }
}
`

const IndexPage = () => {
  return (
    <AnimatePresence>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{type: "spring",
                    mass: 0.35,
                    stiffness: 75,
                    duration: 0.3,
                }}
            > 
    <Background>
      <StaticImage src="../images/EvanMann_111W89thSt_1015_0206-Edit.jpg" alt="Evan Mann" objectFit="cover" placeholder="blurred"/>
    </Background>
    <Main>
      <h1>evan mann <span>architect</span></h1>
      <h3><a href="mailto:evan@evanmannarchitect.com">evan@evanmannarchitect.com</a></h3>
      <h3><a href="https://instagram.com/evanmann_architect" target="_blank" rel="noreferrer">@evanmann_architect</a></h3>
    </Main>
    </motion.div>
    </AnimatePresence>
  )
}

export default IndexPage

export const Head = () => (
  <>
    <title>Evan Mann - Architect</title>
    <meta name="description" content="Evan Mann is an architect working in commercial and residential renovation and new construction." />
    <meta name="keywords" content="Evan Mann, architect, architecture, New York City, New York, residential, commercial, renovation, new construction" /> 
  </>
)